<template>
  <h1>
    Fin du tour
  </h1>
  <p>Résumé</p>
  <p>Conseils</p>
  <p>Détails</p>
  
  <div class="game-controls">
    <button @click="next">Continuer</button>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push({ name: 'screensaver' , query: { debug: this.isDebugMode ? '1' : null }});
    },
  }
}
</script>

<style lang="scss">
div.game-controls {

  button {
    padding: 0.5em;
    margin: 0.5em;
  }
}
</style>