<template>
  <h1>
    Tour #1
  </h1>
  <p>Profil</p>
  <p>Dernière action</p>
  <p>Revenus</p>

  <div class="game-controls">
    <button @click="next">Continuer</button>
    <button @click="restart">Recommencer le jeu</button>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push({ name: 'risk', query: { debug:  this.$store.state.debugMode ? '1' : null } });
    },
    restart() {
      this.$router.push({ name: 'avatar', query: { debug: this.$store.state.debugMode ? '1' : null } });
    },
  }
}
</script>

<style lang="scss">
div.game-controls {

  button {
    padding: 0.5em;
    margin: 0.5em;
  }
}
</style>