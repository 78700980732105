// store/index.js
import { createStore } from 'vuex';

const packageVersion = process.env.VUE_APP_PACKAGE_VERSION || '0.0.0';
const splits = packageVersion.split('.');
let appVersion;

if (splits[2] === '0') {
    appVersion = splits[0] + '.' + splits[1];
} else {
    appVersion = packageVersion;
}

const store = createStore({
    state() {
        return {
            packageVersion: appVersion,
            debugMode: false,  // Ajout du debugMode dans l'état
        };
    },
    mutations: {
        setDebugMode(state, status) {
            state.debugMode = status;
        }
    },
    actions: {
        enableDebugMode({ commit }) {
            commit('setDebugMode', true);
        },
        disableDebugMode({ commit }) {
            commit('setDebugMode', false);
        }
    }
});

export default store;
