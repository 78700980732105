<template>
    
    <h1>
      Avatar
    </h1>
    <p>
      Choix de l'avatar...
    </p>
    <h2>
      Place ta carte pour valider ton avatar !
    </h2>
  </template>