<template>
  <div v-if="isDebugMode" class="debug-header">
    <button class="debug-button" @click="goToHome">Carte vide</button>
    <button class="debug-button" @click="goToRound()">Carte tour 1</button>
  </div>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    }
  },
  mounted() {
    this.$router.isReady().then(()=>{
      
      if (this.$route.query.debug == '1') {
        this.$store.dispatch('enableDebugMode');
      }
    });
  },  
  methods: {
    goToHome() {
      if( this.$route.name == 'avatar'){
        this.$router.push({ name: 'screensaver', query: { debug: this.$store.state.debugMode ? '1' : null } });
      }else{
        this.$router.push({ name: 'avatar', query: { debug: this.$store.state.debugMode ? '1' : null } });
      }
      
    },
    goToRound() {
      if( this.$route.name == 'avatar'){
        this.$router.push({ name: 'screensaver', query: { debug: this.$store.state.debugMode ? '1' : null } });
      }else{
        this.$router.push({ name: 'profile', query: { debug: this.$store.state.debugMode ? '1' : null } });
      }
    }
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&display=swap');

body {
  margin: 0px;
  overflow: hidden;
}

div.container{
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.debug-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8d7da;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

.debug-button {
  background-color: red;
  color: white;
  padding: 0.5em;
  border: none;
  cursor: pointer;
  margin: 0.5em;
}

div.content{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  max-width: 50em;
}

div.footer{
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: smaller;
  color: $light-text-color;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary-color;

  h1{
    margin-top:150px;
  }
}
</style>
